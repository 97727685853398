import { Winnetou } from "../node_modules/winnetoujs/src/winnetou.js";
import _strings from "./_strings.js";
import { anim } from "../node_modules/win-animate/src/animate.js";
import {
  country,
  footer,
  forgot,
  lgpd,
  login,
  mainDiv,
  phone,
  register,
  resourcesDiv,
  smoke,
  h2,
} from "./constructos/commons.js";
import { CompatibilityTester } from "../libs_notLogged/compatibilityTester.js";
import Log from "../libs_notLogged/logs.js";
const l = new Log(true, "notLogged/pc.js");
import * as api from "../libs_notLogged/fetch.js";
import { ButtonLoader } from "../libs_notLogged/buttonLoader.js";
import { InputValidator } from "../libs_notLogged/inputValidator.js";
import {
  ageVerification,
  addListerDateChange,
  checkDateFormat,
} from "../libs_notLogged/dateOps.js";
import { Router } from "../libs_notLogged/router.js";
import { fullScreenLoader } from "../libs_notLogged/loaders.js";
//

export class Base {
  /**
   *
   *{tipo_:"",identificador:""}
   */
  android(msg) {
    try {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(msg);
    } catch (e) {
      // do nothing
    }
  }

  initWatcher(commonInstance) {
    document.addEventListener(
      "message",
      /**
       * Message received from android app
       * @param {MessageEvent} message
       */
      async message => {
        //

        var payload = JSON.parse(message.data);

        switch (payload.title) {
          case "twitterSignIn":
            let { name, email, authToken, authTokenSecret } =
              payload.data;

            await this.twitterLogin(
              name,
              email,
              authToken,
              authTokenSecret
            );
            break;

          case "facebookSignIn":
            this.facebookLogin(
              payload.data.id,
              payload.data.name,
              payload.data.email,
              false
            );
            break;
        }
      }
    );
  }

  testChromeVersion() {
    let compatibility = CompatibilityTester();

    if (!compatibility) {
      // console.log("Web view desatualizada");
      h2({ text: _strings.browserNotSupported }).create("#app");
    } else {
      // console.log("Webview ok");
      // this.chooseCountry();
      // this.render();
      Winnetou.setMutable("country", "BR");
      new Router(this).create();

      // aqui ele chama o render
      Winnetou.navigate("/");
    }
  }

  chooseCountry(change = false) {
    //
    l.printMsg(["language", navigator.language]);

    let country_ = Winnetou.getMutable("country");

    try {
      if (navigator.language === "pt-BR" && !change && !country_) {
        Winnetou.setMutable("country", "BR");
        return;
      }
    } catch (e) {}

    let maps_ = Winnetou.getMutable("maps");

    if (!country_ || change) {
      let c = country({
        actionConfirm: Winnetou.fx(() => {
          let val = Winnetou.select(c.ids.select).getVal();

          var e = document.getElementById(c.ids.select);
          // @ts-ignore
          var option = e.options[e.selectedIndex];

          let timeline = option.getAttribute("data-timeline");
          let maps = option.getAttribute("data-maps");

          console.log(val, timeline, maps);

          if (val === "0") {
            return false;
          } else {
            Winnetou.setMutable("country", timeline);
            Winnetou.setMutable("maps", maps);
            Winnetou.changeLang(val);

            /**
             * Agora tenho o country para
             * criar a timeline correta
             * e o maps para saver de qual pais puxar
             * o resultado das cidades
             */
          }
        }),
      }).create("#app");
    }
  }

  render_login() {
    Winnetou.select("body").css("overflow", "hidden");
    Winnetou.select("#lgpd-win-div").remove();

    let l = login(
      {
        show: _strings.show,
        action: Winnetou.fx(async () => {
          // Login submit
          Winnetou.select(l.ids.username_span).html("");
          Winnetou.select(l.ids.password_span).html("");

          let username = Winnetou.select(l.ids.username).getVal();

          if (username === "") {
            Winnetou.select(l.ids.username_span).html(
              _strings.empty_username
            );

            return;
          }

          let password = Winnetou.select(l.ids.password).getVal();

          if (password === "") {
            Winnetou.select(l.ids.password_span).html(
              _strings.empty_password
            );

            return;
          }

          let loader = new ButtonLoader(l.ids.submit);
          loader.loading();

          let response = await api.post("/api_v2/login", {
            user: username,
            pass: password,
          });

          switch (response) {
            case "ok":
              this.android("login_ok");
              location.href = "/";
              break;

            case "cookieNotSupported":
              this.updateBrowserMessage();
              loader.clean();

              break;

            case "notFound":
              Winnetou.select(l.ids.username_span).html(
                _strings.notFound_username +
                  ` <br><b>"${username}"</b><br> ` +
                  _strings.notFound_username_2
              );
              loader.clean();
              break;

            case "passwordError":
              Winnetou.select(l.ids.password_span).html(
                _strings.password_error
              );
              loader.clean();
              break;

            case "banned":
              Winnetou.select(l.ids.username_span).html(
                _strings.userBanned
              );
              loader.clean();
              break;

            case "deactivated":
              Winnetou.select(l.ids.username_span).html(
                _strings.userDeactivatedPassError
              );
              loader.clean();
              break;

            case "canRestore_warning":
              let foo1 = window.confirm(
                "Este perfil foi cancelado por você e já foi reativado uma vez. Se você reativá-lo agora, não poderá cancelar e reativar novamente. Deseja reativar o seu perfil?"
              );
              if (foo1) {
                loader.clean();
                await api.post("/api_v2/login/restore", {
                  user: username,
                  pass: password,
                });
                document.getElementById(l.ids.submit).click();
              } else {
                loader.clean();
              }
              break;

            case "cannotRestore":
              Winnetou.select(l.ids.username_span).html(
                "Este perfil ultrapassou o limite de restauração de contas e está permanentemente cancelado."
              );
              loader.clean();
              break;

            case "canRestore":
              let foo = window.confirm(_strings.restoreAccount);
              if (foo) {
                loader.clean();
                await api.post("/api_v2/login/restore", {
                  user: username,
                  pass: password,
                });
                document.getElementById(l.ids.submit).click();
              } else {
                loader.clean();
              }
              break;
          }
        }),

        login_text: _strings.username,
        pass_text: _strings.password,

        submit_text: _strings.enter,
        title: _strings.login_title,

        action_back: Winnetou.fx(() => {
          Winnetou.navigate("/");
        }),
        action_forgot: Winnetou.fx(() => {
          Winnetou.navigate("/passwordRecovery");
        }),
        action_register: Winnetou.fx(() => {
          Winnetou.navigate("/register");
        }),
        back: _strings.back,
        forgot: _strings.forgot,
        register: _strings.register,
      },
      { identifier: "div" }
    ).create("#app");

    anim(l.ids.modal, "slideInLeft");
  }

  render_register() {
    Winnetou.select("body").css("overflow", "hidden");
    Winnetou.select("#lgpd-win-div").remove();

    let r = register(
      {
        show: _strings.show,

        accept_link_text: _strings.accept_link_text,
        accept_text: _strings.accept_text,
        action: Winnetou.fx(async () => {
          // send action

          let bDate = Winnetou.select(r.ids.birth).getVal();

          // remove avisos de erro dos spans de erro
          Winnetou.select(r.ids.email_span).html("");
          Winnetou.select(r.ids.password_span).html("");
          Winnetou.select(r.ids.birth_span).html("");
          Winnetou.select(r.ids.check_span).html("");

          let email = Winnetou.select(r.ids.email).getVal();

          if (!new InputValidator(r.ids.email).isEmail()) {
            Winnetou.select(r.ids.email_span).html(
              _strings.invalidEmail
            );
            return;
          }

          let password = Winnetou.select(r.ids.password).getVal();

          if (password === "") {
            Winnetou.select(r.ids.password_span).html(
              _strings.empty_password
            );
            return;
          }

          if (!bDate) {
            Winnetou.select(r.ids.birth_span).html(
              _strings.empty_birth
            );
            return;
          }
          if (!checkDateFormat(bDate)) {
            Winnetou.select(r.ids.birth_span).html(
              _strings.birth_error
            );
            return;
          }

          let verification = ageVerification(bDate);
          if (!verification) {
            Winnetou.select(r.ids.birth_span).html(_strings.under18);
            return;
          }

          let check = Winnetou.select(r.ids.accept).isChecked();
          if (!check) {
            Winnetou.select(r.ids.check_span).html(
              _strings.empty_check
            );
            return;
          }

          let loader = new ButtonLoader(r.ids.submit);
          loader.loading();

          let country_ = Winnetou.getMutable("country");
          let maps_ = Winnetou.getMutable("maps");

          let response = await api.post("/api_v2/register", {
            email,
            pass: password,
            birth: bDate,
            country_,
            maps_,
          });

          switch (response) {
            case "invalidEmail":
              Winnetou.select(r.ids.email_span).html(
                _strings.invalidEmail
              );
              loader.clean();
              break;

            case "tempEmail":
              Winnetou.select(r.ids.email_span).html(
                "Seu provedor de email não é aceito em nossa plataforma. Use provedores de emails conhecidos como gmail, hotmail e yahoo para efetuar seu cadastro ou use nosso login social."
              );
              loader.clean();
              break;

            case "cookieNotSupported":
              this.updateBrowserMessage();
              loader.clean();

              break;

            case "emptyField":
              Winnetou.select(r.ids.email_span).html(
                _strings.emptyFields
              );
              loader.clean();

              break;

            case "emailExists":
              Winnetou.select(r.ids.email_span).html(
                _strings.usedEmail
              );
              loader.clean();

              break;

            case "ok":
              this.android("login_ok");

              try {
                // @ts-ignore
                twttr.conversion.trackPid("o7h2i", {
                  tw_sale_amount: 0,
                  tw_order_quantity: 0,
                });
              } catch (e) {}

              setTimeout(() => {
                location.href = "/";
              }, 2000);
              break;
          }
        }),
        action_back: Winnetou.fx(() => {
          Winnetou.navigate("/");
        }),
        action_login: Winnetou.fx(() => {
          Winnetou.select(r.ids.register).remove();
          Winnetou.navigate("/login");
        }),
        back: _strings.back,
        login: "Já possui cadastro? Entre aqui.",
        pass_text: _strings.password,
        birth_text: _strings.birth,
        email_text: _strings.email,
        submit_text: "Criar conta",
        title: _strings.register,
      },
      { identifier: "div" }
    ).create("#app");

    addListerDateChange(r.ids.birth);

    anim(r.ids.modal, "slideInLeft");
  }

  render_passwordRecovery() {
    Winnetou.select("body").css("overflow", "hidden");

    let l = forgot(
      {
        action: Winnetou.fx(async () => {
          // Login submit
          Winnetou.select(l.ids.username_span).html("");

          let username = Winnetou.select(l.ids.username).getVal();

          if (username === "") {
            Winnetou.select(l.ids.username_span).html(
              _strings.empty_username
            );

            return;
          }

          let loader = new ButtonLoader(l.ids.submit);
          loader.loading();

          let response = await api.post("/api_v2/login/recovery", {
            user: username,
          });

          switch (response) {
            case "ok":
              loader.success(_strings.success);
              Winnetou.select(l.ids.successMessage).html(
                _strings.emailSent
              );
              break;

            case "notFound":
              Winnetou.select(l.ids.username_span).html(
                _strings.notFound_username +
                  ` <br><b>"${username}"</b><br> ` +
                  _strings.notFound_username_2
              );
              loader.clean();
              break;
          }
        }),

        submit_text: _strings.submit,
        title: _strings.titleForgot,

        action_back: Winnetou.fx(() => {
          Winnetou.navigate("/");
        }),
        action_register: Winnetou.fx(() => {
          Winnetou.navigate("/register");
        }),
        back: _strings.back,
        register: _strings.register,
        action_login: Winnetou.fx(() => {
          Winnetou.navigate("/login");
        }),
        forgot_text: _strings.forgotText,
        login: _strings.login,
      },
      { identifier: "div" }
    ).create("#app");

    anim(l.ids.modal, "slideInLeft");
  }

  facebook_login() {
    let t = this;

    this.loader = fullScreenLoader({ type: "general" });

    // @ts-ignore
    FB.login(
      function (response) {
        var accessToken = response.authResponse.accessToken;
        console.log({ accessToken });
        // @ts-ignore
        FB.api("/me?fields=name,email", function (response) {
          console.log("resposta da api", response);
          let { name, email, id } = response;

          if (!name || !email || !id) {
            alert(_strings.weNeedEmail);
            // @ts-ignore
            FB.logout(function (response) {
              // user is now logged out
            });
            t.loader.remove();
            return;
          }

          t.facebookLogin(id, name, email, accessToken);
        });
      },
      { scope: "public_profile,email", auth_type: "rerequest" }
    );
  }

  /**
   *
   * @param {string} id
   * @param {string} name
   * @param {string} email
   * @param {string|boolean} accessToken
   * @returns
   */
  async facebookLogin(id, name, email, accessToken) {
    //

    this.android("login_ok");

    let res = await api.post("/api_v2/facebook/webSignIn", {
      id,
      name,
      email,
      accessToken,
    });

    switch (res) {
      case "userBanned":
        this.android("loader_finish");
        alert(_strings.userBanned);
        this.loader.remove();
        return true;
        break;

      case "userDeactivated":
        this.android("loader_finish");
        alert(_strings.userDeactivated);
        this.loader.remove();
        return true;
        break;

      case "notAuthorized":
        this.android("loader_finish");
        alert(_strings.notAuthorized);
        this.loader.remove();
        return true;
        break;

      case "notAuthorizedReact":
        this.android("loader_finish");
        alert(
          "Você se cadastrou usando seu email e senha, por isso não pode entrar usando o Facebook. Entre normalmente usando sua senha previamente escolhida, caso tenha a esquecido, clique em 'esqueci minha senha' para criar uma nova."
        );
        this.loader.remove();
        return true;
        break;

      case "ok_register":
        try {
          // @ts-ignore
          twttr.conversion.trackPid("o7h2i", {
            tw_sale_amount: 0,
            tw_order_quantity: 0,
          });
        } catch (e) {}

        setTimeout(() => {
          location.href = "/";
        }, 2000);

        break;

      case "ok_login":
        location.href = "/";
        break;
    }

    //
  }

  async twitterLogin(name, email, authToken, authTokenSecret) {
    // chama o loader do react-native
    this.android("login_ok");

    // faz a chamada para o backend

    let res = await api.post("/api_v2/twitter/appSignIn", {
      name,
      email,
      authToken,
      authTokenSecret,
    });

    // redireciona de acordo com a resposta

    switch (res) {
      case "userBanned":
        this.android("loader_finish");
        alert(_strings.userBanned);
        return true;
        break;

      case "userDeactivated":
        this.android("loader_finish");
        alert(_strings.userDeactivated);
        return true;
        break;

      case "notAuthorized":
        this.android("loader_finish");
        alert(_strings.notAuthorized);
        return true;
        break;

      case "ok":
        location.href = "/";
        break;
    }
  }

  updateBrowserMessage() {}
}
