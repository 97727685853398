export function CompatibilityTester() {
  "use strict";

  if (typeof Symbol == "undefined") return false;
  try {
    eval("class Foo {}");
    eval("var bar = (x) => x+1");
    let a = {};
    if (a.b?.c) {
    }
  } catch (e) {
    return false;
  }

  return true;
}
