import { Winnetou } from "../node_modules/winnetoujs/src/winnetou.js";
import { feedLoader } from "../js/constructos/loaders.js";
export class ButtonLoader {
  /**
   * Transforma um botão em loader
   * @param {string} btn btn id
   */
  constructor(btn) {
    this.btn = btn;
    try {
      this.text = Winnetou.select(this.btn).getHtml();
    } catch (e) {
      this.text = "";
    }
  }
  loading() {
    try {
      Winnetou.select(this.btn)
        .disable()
        .html(feedLoader().constructoString());
    } catch (e) {}
  }

  clean() {
    try {
      Winnetou.select(this.btn).enable().html(this.text);
    } catch (e) {}
  }

  success(successText) {
    try {
      Winnetou.select(this.btn).html(successText);
    } catch (e) {}
  }

  progress(value) {
    try {
      Winnetou.select(this.btn).html(`
    ${value}%
    <div class="progress" style="     
      width:${value}%;      
    "></div>
    `);
    } catch (e) {}
  }
}
