export default class Log {
  /**
   * @param {string} file
   * @param {boolean} print
   * @param {number} [version]
   * @param {number} [globalVersion]
   */
  constructor(print = true, file, version = 0, globalVersion = 0) {
    this.globalVersionControl = 2;
    this.globalVersion = globalVersion;
    this.print = print;
    this.file = file;
    this.version = version;
  }

  /**
   * @param {string | array} msg_
   * @param {number} [version]
   */
  printMsg(msg_, version = 0) {
    return;

    if (
      this.print &&
      this.version === version &&
      this.globalVersion === this.globalVersionControl
    ) {
      console.log(" ");
      console.log("------------- v:" + this.version);
      console.log(`Debug true: ${this.file}`);

      if (typeof msg_ === "string") {
        console.log(msg_);
      } else {
        msg_.forEach(msg => {
          console.log(msg);
        });
      }

      console.log("-------------");
      console.log(" ");
    }
  }

  /**
   * @param {object | array} msg_
   * @param {number} [version]
   */
  printTable(msg_, version = 0) {
    return;

    if (this.print && this.version === version) {
      console.log(" ");
      console.log("------------- v:" + this.version);
      console.log(`Debug true: ${this.file}`);

      console.table(msg_);

      console.log("-------------");
      console.log(" ");
    }
  }

  /**
   * Print a error to console and send email to admin
   * @param {string | array} msg_
   */
  printError(msg_) {
    return;

    /**
     * Todo:
     * ==> add here email method
     */

    if (this.print) {
      console.error(" ");

      console.error("-------------");
      console.error(`Debug true: ${this.file}`);

      if (typeof msg_ === "string") {
        console.error(msg_);
      } else {
        msg_.forEach(msg => {
          console.error(msg);
        });
      }

      console.error("-------------");
      console.error(" ");
    }
  }
}
