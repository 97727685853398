import Log from "./logs.js";
const l = new Log(true, "fetch.js");

/**
 * Function to post json to a API
 * @param  {string} url API endpoint
 * @param  {object} data json body
 * @return {Promise} json data
 */
export const post = (url, data) => {
  return new Promise((resolve, reject) => {
    let body;

    try {
      body = JSON.stringify(data);
    } catch (e) {
      return reject(e.message);
    }

    fetch(url, {
      method: "POST",
      body,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          return reject(response);
        }
      })
      .then(function (data) {
        return resolve(data);
      })
      .catch(function (error) {
        return reject(error);
      });
  });
};

/**
 * Function to get json from API
 * @param {string} url API Endpoint
 * @param {"default"|"only-if-cached"|"reload"} cache default defaults
 * @param {boolean} autoRecache if true, recache automatically. Default false;
 * @returns {Promise.<any>}
 */
export const get = (url, cache = "default", autoRecache = false) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      cache: cache,
      credentials: "same-origin",
      mode: "same-origin",
    })
      .then(function (response) {
        if (response.ok) {
          if (autoRecache) {
            fetch(url, {
              method: "GET",
            });
          }
          return response.json();
        } else {
          fetch(url, {
            method: "GET",
          })
            .then(e => location.reload())
            .catch(error => false);
          return reject("erro no fetch code gh4322" + response);
        }
      })
      .then(function (data) {
        return resolve(data);
      })
      .catch(function (error) {
        l.printMsg(["erro no fetch get code k9ol9", error]);

        if (cache == "only-if-cached") {
          fetch(url, {
            method: "GET",
          })
            .then(function (response) {
              if (response.ok) {
                return response.json();
              } else {
                return reject("code j543k23b" + response);
              }
            })
            .then(function (data) {
              return resolve(data);
            })
            .catch(function (error) {
              return reject("code hh23412" + error);
            });
        } else {
          return reject("code h345j1234kl32" + error);
        }
      });
  });
};

export const recache = url => {
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "GET",
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          return reject("code n77h67" + response);
        }
      })
      .then(function (data) {
        return resolve(data);
      })
      .catch(function (error) {
        return reject("code l09loi8" + error);
      });
  });
};

/**
 * Send ajax data and handle progress
 * @param {string} url API Endpoint
 * @param {object} data fields
 * @param {any} fx progress handler
 * @example
 * 
  let loader = new ButtonLoader(this.sendProofBtn.ids.button);

  api
    .formData(
      "/api_v2/plans/boleto/sendProof",
      {
        file: Winnetou.select(this.hidden.ids.value).getFile(),
      },
      e => {
        if (e.lengthComputable) {
          var ratio = Math.floor((e.loaded / e.total) * 100);
          loader.progress(ratio);
        }
      }
    )
    .then(r => {
      new Alert().toast(Strings.receiptSent);
      Winnetou.select(this.sendProofBtn.ids.button).remove();
    })
    .catch(e => {
      l.printMsg(["Erro ao enviar form", e]);
      Winnetou.select(this.sendProofBtn.ids.button).remove();
    });
}
 */
export const formData = (url, data, fx) => {
  return new Promise(async (resolve, reject) => {
    let formData = new FormData();
    let keys = Object.keys(data);

    keys.map(key => {
      let value = data[key];
      formData.append(key, value);
    });

    let xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.upload.onprogress = fx;
    xhr.responseType = "json";

    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        return resolve(this.response || true);
      }
      if (this.readyState == 4 && this.status != 200) {
        return reject(
          "Impossible to send formData. Fetch.js. Error code 2333e43."
        );
      }
    };

    xhr.send(formData);
  });
};
