import { Winnetou } from "../node_modules/winnetoujs/src/winnetou.js";

export class InputValidator {
  //

  /**
   * Inicia a instância de validação
   * @param {string} inputId final input id
   */
  constructor(inputId) {
    /**
     * @private
     */
    this.input = Winnetou.select(inputId).getVal();
  }

  /**
   * Testa o valor do input para saber se está dentro do padrão
   * @param {boolean} [withSpaces] Se true aceita espaços, default false
   * @returns {boolean} Retorna true se está dentro do padrão
   */
  onlyValidCharacters(withSpaces = false) {
    return withSpaces
      ? /^[A-Za-z0-9_-\s]*$/gi.test(this.input)
      : /^[A-Za-z0-9_-]*$/gi.test(this.input);
  }

  /**
   * Testa o valor do input para saber se está dentro do padrão,
   * ACEITA ACENTOS LATINOS
   * @param {boolean} [withSpaces] Se true aceita espaços, default false
   * @returns {boolean} Retorna true se está dentro do padrão
   */
  onlyValidLatinCharacters(withSpaces = false) {
    return withSpaces
      ? /^[A-zÀ-ú0-9_-\s]*$/gi.test(this.input)
      : /^[A-zÀ-ú0-9_-]*$/gi.test(this.input);
  }

  /**
   * Testa o tamanho do valor do input
   * @param {number} length O tamanho máximo que a string pode conter
   * @returns {boolean} Retorna true se está dentro do padrão
   */
  maxLength(length) {
    return this.input.length >= length;
  }

  /**
   * Testa para saber se o input está em branco
   * @returns {boolean} Retorna true se está dentro do padrão
   */
  isEmptyOrInvalid() {
    return !this.input;
  }

  /**
   * @returns {boolean}
   */
  isEmail() {
    var regex =
      /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(this.input);
  }
}
