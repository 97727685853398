import {
  Winnetou
} from "../node_modules/winnetoujs/src/winnetou.js";

export default Winnetou.strings = {

  /** @property Seu navegador não suporta todas as funções e recursos que o a3menage possui, aconselhamos que você mantenha seu navegador sempre atualizado. Indicamos o Google Chrome. Gostaria de instalar o Chrome agora? */
  updateBrowserMessagePc: "Seu navegador não suporta todas as funções e recursos que o a3menage possui, aconselhamos que você mantenha seu navegador sempre atualizado. Indicamos o Google Chrome. Gostaria de instalar o Chrome agora?",

  /** @property O app do a3menage precisa que você tenha instalado a versão mais recente do Google Chrome no seu celular. Gostaria de atualizar agora? Após atualizar, reinicie o a3menage. */
  updateBrowserMessageApp: "O app do a3menage precisa que você tenha instalado a versão mais recente do Google Chrome no seu celular. Gostaria de atualizar agora? Após atualizar, reinicie o a3menage.",

  /** @property Este perfil foi cancelado por você. Gostaria de restaurar seu antigo perfil e voltar a usar o a3menage? */
  restoreAccount: "Este perfil foi cancelado por você. Gostaria de restaurar seu antigo perfil e voltar a usar o a3menage?",

  /** @property Este usuário foi desativado pelo dono da conta. Se você é o dono e esqueceu sua senha você pode clicar em esqueci minha senha para iniciar o processo de restauração de conta. */
  userDeactivatedPassError: "Este usuário foi desativado pelo dono da conta. Se você é o dono e esqueceu sua senha você pode clicar em esqueci minha senha para iniciar o processo de restauração de conta.",

  /** @property Para usar seu facebook para conectar você precisa permitir termos acesso ao seu email. */
  weNeedEmail: "Para usar seu facebook para conectar você precisa permitir termos acesso ao seu email.",

  /** @property Para usar esta função você precisa atualizar o seu aplicativo. Gostaria de fazer isso agora? */
  updateSocial: "Para usar esta função você precisa atualizar o seu aplicativo. Gostaria de fazer isso agora?",

  /** @property mostrar */
  show: "mostrar",

  /** @property Entrar com seu email */
  loginNotLogged: "Entrar com seu email",

  /** @property Você não tem autorização para acessar esta página */
  notAuthorized: "Você não tem autorização para acessar esta página",

  /** @property Continuar com o Facebook */
  facebook: "Continuar com o Facebook",

  /** @property Continuar com o X */
  twitter: "Continuar com o X",

  /** @property Esta conta foi desativada pelo usuário, para voltar ao a3menage faça o registro com um novo email. */
  userDeactivated: "Esta conta foi desativada pelo usuário, para voltar ao a3menage faça o registro com um novo email.",

  /** @property Seu navegador está desatualizado. Por favor, atualize antes de usar o a3menage. */
  browserNotSupported: "Seu navegador está desatualizado. Por favor, atualize antes de usar o a3menage.",

  /** @property Swing & Ménage */
  title: "Swing & Ménage",

  /** @property Encontre um casal perto de você e marque um encontro hoje mesmo! */
  subtitle: "Encontre um casal perto de você e marque um encontro hoje mesmo!",

  /** @property Registrar com seu email */
  register: "Registrar com seu email",

  /** @property Login */
  login: "Login",

  /** @property Termos de uso */
  terms: "Termos de uso",

  /** @property Política de privacidade */
  privacy: "Política de privacidade",

  /** @property LGPD */
  lgpd: "LGPD",

  /** @property Top Recursos */
  div2_title: "Top Recursos",

  /** @property Nosso site e app usam as mais novas tecnologias para trazer para você as melhores experiências. */
  div2_subtitle: "Nosso site e app usam as mais novas tecnologias para trazer para você as melhores experiências.",

  /** @property Rápido */
  title1: "Rápido",

  /** @property Nosso foco é fazer você não perder tempo, por isso empregamos o que há de melhor no mercado para que tudo dentro do site e do aplicativo funcione da forma mais rápida possível. */
  text1: "Nosso foco é fazer você não perder tempo, por isso empregamos o que há de melhor no mercado para que tudo dentro do site e do aplicativo funcione da forma mais rápida possível.",

  /** @property Gratuito */
  title2: "Gratuito",

  /** @property Apesar de termos sim planos de assinatura, a maioria das funções do a3 são gratuitas, proporcionando uma experiência satisfatória mesmo no plano free. */
  text2: "Apesar de termos sim planos de assinatura, a maioria das funções do a3 são gratuitas, proporcionando uma experiência satisfatória mesmo no plano free.",

  /** @property Com inteligência artificial */
  title3: "Com inteligência artificial",

  /** @property Nosso time de desenvolvedores criaram um sistema de recomendações de perfis que utiliza inteligência artificial para entender o que nossos usuários estão procurando, assim você encontrará sempre sugestões atualizadas toda vez que abrir o site ou o aplicativo. */
  text3: "Nosso time de desenvolvedores criaram um sistema de recomendações de perfis que utiliza inteligência artificial para entender o que nossos usuários estão procurando, assim você encontrará sempre sugestões atualizadas toda vez que abrir o site ou o aplicativo.",

  /** @property E sistema de notificações */
  title4: "E sistema de notificações",

  /** @property Diferente de outros sites de encontros, nossos recursos como notificações, mensagens, histórico de visitas e muitos outros estão disponíveis desde o plano free, mantendo você sempre conectado. */
  text4: "Diferente de outros sites de encontros, nossos recursos como notificações, mensagens, histórico de visitas e muitos outros estão disponíveis desde o plano free, mantendo você sempre conectado.",

  /** @property © a3menage.com and its affiliates */
  line1_footer: "© a3menage.com and its affiliates",

  /** @property Made with ❤ in Brazil */
  line2_footer: "Made with ❤ in Brazil",

  /** @property Login */
  login_title: "Login",

  /** @property Entrar */
  enter: "Entrar",

  /** @property Usuário */
  username: "Usuário",

  /** @property Senha */
  password: "Senha",

  /** @property Esqueci minha senha */
  forgot: "Esqueci minha senha",

  /** @property Voltar à tela inicial */
  back: "Voltar à tela inicial",

  /** @property nossos termos de uso e política de privacidade de dados */
  accept_link_text: "nossos termos de uso e política de privacidade de dados",

  /** @property Clicando aqui você confirma que tem mais de 18 anos de idade e que aceita */
  accept_text: "Clicando aqui você confirma que tem mais de 18 anos de idade e que aceita",

  /** @property O usuário não pode ficar em branco. */
  empty_username: "O usuário não pode ficar em branco.",

  /** @property A senha não pode ficar em branco. */
  empty_password: "A senha não pode ficar em branco.",

  /** @property Email ou login não reconhecido. Você digitou:  */
  notFound_username: "Email ou login não reconhecido. Você digitou: ",

  /** @property Por favor, verifique se digitou corretamente e tente novamente. */
  notFound_username_2: "Por favor, verifique se digitou corretamente e tente novamente.",

  /** @property Sua senha está incorreta. Se você esqueceu, clique em esqueci minha senha.  */
  password_error: "Sua senha está incorreta. Se você esqueceu, clique em esqueci minha senha. ",

  /** @property Data de nascimento */
  birth: "Data de nascimento",

  /** @property Email */
  email: "Email",

  /** @property Enviar */
  send: "Enviar",

  /** @property Seu email não é válido. */
  invalidEmail: "Seu email não é válido.",

  /** @property Sua data de nascimento não pode ficar em branco. */
  empty_birth: "Sua data de nascimento não pode ficar em branco.",

  /** @property Sua data de nascimento não é valida, use o formato dd/mm/yyyy, exemplo 24/02/1988 */
  birth_error: "Sua data de nascimento não é valida, use o formato dd/mm/yyyy, exemplo 24/02/1988",

  /** @property Você deve ler e aceitar nossos termos de uso para criar uma conta. */
  empty_check: "Você deve ler e aceitar nossos termos de uso para criar uma conta.",

  /** @property Para se cadastrar você precisa ser maior de idade. */
  under18: "Para se cadastrar você precisa ser maior de idade.",

  /** @property Alguns campos obrigatórios estão vazios, por favor, verifique. */
  emptyFields: "Alguns campos obrigatórios estão vazios, por favor, verifique.",

  /** @property Este email encontra-se indisponível. */
  usedEmail: "Este email encontra-se indisponível.",

  /** @property Ménage à trois & Swing */
  top_text: "Ménage à trois & Swing",

  /** @property ou */
  or: "ou",

  /** @property Aceitar */
  accept: "Aceitar",

  /** @property Nosso site usa cookies para armazenar dados e informações personalizadas, assim como cookies de terceiros. Para continuar usando nosso site você deve aceitar o uso de cookies. */
  lgpdText: "Nosso site usa cookies para armazenar dados e informações personalizadas, assim como cookies de terceiros. Para continuar usando nosso site você deve aceitar o uso de cookies.",

  /** @property Leia nossa política de privacidade e nossas diretrizes LGPD. */
  lgpdLink: "Leia nossa política de privacidade e nossas diretrizes LGPD.",

  /** @property Recuperação de senha */
  titleForgot: "Recuperação de senha",

  /** @property Enviar */
  submit: "Enviar",

  /** @property Para recuperar sua senha insira seu email ou login aqui e clique em enviar. */
  forgotText: "Para recuperar sua senha insira seu email ou login aqui e clique em enviar.",

  /** @property Sucesso. */
  success: "Sucesso.",

  /** @property Enviamos para você um email com sua nova senha. Por favor, não esqueça de verificar sua caixa de spam, promoções e social. */
  emailSent: "Enviamos para você um email com sua nova senha. Por favor, não esqueça de verificar sua caixa de spam, promoções e social.",

  /** @property You haven't confirmed your email yet and some functions will only be released after you confirm. Check your email and click on the link we sent to confirm your registration. If you still haven't received our email click here. We do this to ensure your safety. -  */
  emailNotChecked: "You haven't confirmed your email yet and some functions will only be released after you confirm. Check your email and click on the link we sent to confirm your registration. If you still haven't received our email click here. We do this to ensure your safety. - ",

  /** @property Este usuário foi banido do a3menage. */
  userBanned: "Este usuário foi banido do a3menage.",

  /** @property Alterar país */
  changeCountry: "Alterar país",

  /** @property Escolha */
  dp_title: "Escolha",

  /** @property Cancelar */
  dp_cancel: "Cancelar",

  /** @property Ok */
  dp_confirm: "Ok",

  /** @property  (Ano) */
  dp_year: " (Ano)",

  /** @property  (Mês) */
  dp_month: " (Mês)",

  /** @property  (Dia) */
  dp_day: " (Dia)",

}