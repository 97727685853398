import { Winnetou } from "../node_modules/winnetoujs/src/winnetou.js";

export class Router {
  constructor(parent) {
    this.parent = parent;
  }

  android(msg) {
    try {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(msg);
    } catch (e) {
      // do nothing
    }
  }

  create() {
    Winnetou.createRoutes({
      "/": () => {
        this.removeModals();
        Winnetou.select("body").css("overflow-y", "auto");
        this.android("canExit");
        this.parent.render();
      },
      "/login": () => {
        this.android("canNotExit");

        this.removeModals();
        this.parent.render_login();
      },
      "/register": () => {
        this.android("canNotExit");

        this.removeModals();

        this.parent.render_register();
      },
      "/passwordRecovery": () => {
        this.android("canNotExit");

        this.removeModals();

        this.parent.render_passwordRecovery();
      },
    });
  }

  removeModals() {
    Winnetou.select("#login-win-div").remove();
    Winnetou.select("#register-win-div").remove();
    Winnetou.select("#forgot-win-div").remove();
  }
}
