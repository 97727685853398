import {
  Winnetou
} from "../../node_modules/winnetoujs/src/winnetou.js";
import {
  Constructos
} from "../../node_modules/winnetoujs/src/constructos.js";



/**@private */
class country_ extends Constructos {

  // ========================================
  /**
   * selecionar país
   * @param {object} elements
   * @param {any} elements.actionConfirm  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="country-win-${identifier}" class="country">
    <div class="countryContainer">
      <h2>Choose your country / Elige tu país</h2>

      <select id="select-win-${identifier}">
        <option default value="0">Select</option>
        <option value="en-us" data-timeline="US" data-maps="United States of America">
          United States
        </option>
        <option value="pt-br" data-timeline="BR" data-maps="Brasil">
          Brasil
        </option>
      </select>
      <br>
      <button onclick="${(elements_?.actionConfirm)}">Confirm</button>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            country: `country-win-${identifier}`,
            select: `select-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`country-win-${identifier}`, elements, options, country_);
    return obj;
  }
} /**@private */
class h2_ extends Constructos {

  // ========================================
  /**
   * default h2
   * @param {object} elements
   * @param {any} elements.text  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <h2 id="h2-win-${identifier}">${(elements_?.text)}</h2>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            h2: `h2-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`h2-win-${identifier}`, elements, options, h2_);
    return obj;
  }
} /**@private */
class mainDiv_ extends Constructos {

  // ========================================
  /**
   * main div pc
   * @param {object} elements
   * @param {any} elements.title  
   * @param {any} elements.sub  
   * @param {any} elements.action_register  
   * @param {any} elements.register  
   * @param {any} elements.login_action  
   * @param {any} elements.login  
   * @param {any} elements.blog  
   * @param {any} elements.blog  
   * @param {any} elements.terms_link  
   * @param {any} elements.terms  
   * @param {any} elements.privacy_link  
   * @param {any} elements.privacy  
   * @param {any} elements.lgpd_link  
   * @param {any} elements.lgpd  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="mainDiv-win-${identifier}" class="mainDiv">
    <div class="box" id="box-win-${identifier}">
      <div class="cover"></div>
      <img src="/images/branding/logomarca-retina-laranja.png" alt="a3manege logo" class="logo" id="logo-win-${identifier}" >
      <div class="title">${(elements_?.title)}</div>
      <div class="subtitle">${(elements_?.sub)}</div>
      <div class="buttons">
        
        

        
        

        <button class="register" onclick="${(elements_?.action_register)}">
          <div class="left">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-envelope-square fa-w-14 fa-7x">
              <path fill="currentColor" d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM178.117 262.104C87.429 196.287 88.353 196.121 64 177.167V152c0-13.255 10.745-24 24-24h272c13.255 0 24 10.745 24 24v25.167c-24.371 18.969-23.434 19.124-114.117 84.938-10.5 7.655-31.392 26.12-45.883 25.894-14.503.218-35.367-18.227-45.883-25.895zM384 217.775V360c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V217.775c13.958 10.794 33.329 25.236 95.303 70.214 14.162 10.341 37.975 32.145 64.694 32.01 26.887.134 51.037-22.041 64.72-32.025 61.958-44.965 81.325-59.406 95.283-70.199z" class=""></path>
            </svg>
          </div>
          ${(elements_?.register)}
        </button>

        <button class="login" onclick="${(elements_?.login_action)}">
          <div class="left">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-in-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sign-in-alt fa-w-16 fa-7x">
              <path fill="currentColor" d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z" class=""></path>
            </svg>
          </div>
          ${(elements_?.login)}
        </button>
      </div>
      <div class="links">
        
        <a href="${(elements_?.blog)}"> ${(elements_?.blog)} </a>
        <a href="${(elements_?.terms_link)}"> ${(elements_?.terms)} </a>
        <a href="${(elements_?.privacy_link)}"> ${(elements_?.privacy)} </a>
        <a href="${(elements_?.lgpd_link)}"> ${(elements_?.lgpd)} </a>
      </div>
    </div>
    <div></div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            mainDiv: `mainDiv-win-${identifier}`,
            box: `box-win-${identifier}`,
            logo: `logo-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`mainDiv-win-${identifier}`, elements, options, mainDiv_);
    return obj;
  }
} /**@private */
class mainDivMobile_ extends Constructos {

  // ========================================
  /**
   * main div mobile
   * @param {object} elements
   * @param {any} elements.top_text  
   * @param {any} elements.action_googleplay  
   * @param {any} elements.googleplay  
   * @param {any} elements.action_register  
   * @param {any} elements.register  
   * @param {any} elements.login_action  
   * @param {any} elements.login  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="mainDivMobile-win-${identifier}" class="mainDivMobile">
    <div class="top">
      <img src="/images/branding/white_logo.png" alt="logotipo a3menage.com" >
      <p>${(elements_?.top_text)}</p>
    </div>
    <div class="bottom">
      <div class="buttons">
        <button style="display: none" class="googleplay" onclick="${(elements_?.action_googleplay)}">
          <div class="left">
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-google-play fa-w-16 fa-3x">
              <path fill="currentColor" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" class=""></path>
            </svg>
          </div>
          ${(elements_?.googleplay)}
        </button>

        

        
        

        

        <button class="register" onclick="${(elements_?.action_register)}">
          <div class="left">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-envelope-square fa-w-14 fa-7x">
              <path fill="currentColor" d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM178.117 262.104C87.429 196.287 88.353 196.121 64 177.167V152c0-13.255 10.745-24 24-24h272c13.255 0 24 10.745 24 24v25.167c-24.371 18.969-23.434 19.124-114.117 84.938-10.5 7.655-31.392 26.12-45.883 25.894-14.503.218-35.367-18.227-45.883-25.895zM384 217.775V360c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V217.775c13.958 10.794 33.329 25.236 95.303 70.214 14.162 10.341 37.975 32.145 64.694 32.01 26.887.134 51.037-22.041 64.72-32.025 61.958-44.965 81.325-59.406 95.283-70.199z" class=""></path>
            </svg>
          </div>
          ${(elements_?.register)}
        </button>

        <button class="login" onclick="${(elements_?.login_action)}">
          <div class="left">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-in-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sign-in-alt fa-w-16 fa-7x">
              <path fill="currentColor" d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z" class=""></path>
            </svg>
          </div>
          ${(elements_?.login)}
        </button>
      </div>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            mainDivMobile: `mainDivMobile-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`mainDivMobile-win-${identifier}`, elements, options, mainDivMobile_);
    return obj;
  }
} /**@private */
class mainDivApp_ extends Constructos {

  // ========================================
  /**
   * main div app
   * @param {object} elements
   * @param {any} elements.top_text  
   * @param {any} elements.action_googleplay  
   * @param {any} elements.googleplay  
   * @param {any} elements.action_register  
   * @param {any} elements.register  
   * @param {any} elements.login_action  
   * @param {any} elements.login  
   * @param {any} elements.action_country  
   * @param {any} elements.country  
   * @param {any} elements.action_terms  
   * @param {any} elements.terms  
   * @param {any} elements.action_privacy  
   * @param {any} elements.privacy  
   * @param {any} elements.action_lgpd  
   * @param {any} elements.lgpd  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="mainDivApp-win-${identifier}" class="mainDivMobile mainDivApp">
    <div class="top">
      <img src="/images/branding/white_logo.png" alt="logotipo a3menage.com" >
      <p>${(elements_?.top_text)}</p>
    </div>
    <div class="bottom">
      <div class="buttons">
        <button style="display: none" class="googleplay" onclick="${(elements_?.action_googleplay)}">
          <div class="left">
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google-play" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-google-play fa-w-16 fa-3x">
              <path fill="currentColor" d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" class=""></path>
            </svg>
          </div>
          ${(elements_?.googleplay)}
        </button>

        


        

        <button class="register" onclick="${(elements_?.action_register)}">
          <div class="left">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-envelope-square fa-w-14 fa-7x">
              <path fill="currentColor" d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM178.117 262.104C87.429 196.287 88.353 196.121 64 177.167V152c0-13.255 10.745-24 24-24h272c13.255 0 24 10.745 24 24v25.167c-24.371 18.969-23.434 19.124-114.117 84.938-10.5 7.655-31.392 26.12-45.883 25.894-14.503.218-35.367-18.227-45.883-25.895zM384 217.775V360c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24V217.775c13.958 10.794 33.329 25.236 95.303 70.214 14.162 10.341 37.975 32.145 64.694 32.01 26.887.134 51.037-22.041 64.72-32.025 61.958-44.965 81.325-59.406 95.283-70.199z" class=""></path>
            </svg>
          </div>
          ${(elements_?.register)}
        </button>

        <button class="login" onclick="${(elements_?.login_action)}">
          <div class="left">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-in-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sign-in-alt fa-w-16 fa-7x">
              <path fill="currentColor" d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z" class=""></path>
            </svg>
          </div>
          ${(elements_?.login)}
        </button>
      </div>

      <div class="links">
        <button class="btn_link" onclick="${(elements_?.action_country)}">
          ${(elements_?.country)}
        </button>

        <button class="btn_link" onclick="${(elements_?.action_terms)}">
          ${(elements_?.terms)}
        </button>

        <button class="btn_link" onclick="${(elements_?.action_privacy)}">
          ${(elements_?.privacy)}
        </button>

        <button class="btn_link" onclick="${(elements_?.action_lgpd)}">
          ${(elements_?.lgpd)}
        </button>
      </div>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            mainDivApp: `mainDivApp-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`mainDivApp-win-${identifier}`, elements, options, mainDivApp_);
    return obj;
  }
} /**@private */
class resourcesDiv_ extends Constructos {

  // ========================================
  /**
   * div 2
   * @param {object} elements
   * @param {any} elements.title  
   * @param {any} elements.subtitle  
   * @param {any} elements.title1  
   * @param {any} elements.text1  
   * @param {any} elements.title2  
   * @param {any} elements.text2  
   * @param {any} elements.title3  
   * @param {any} elements.text3  
   * @param {any} elements.title4  
   * @param {any} elements.text4  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="resourcesDiv-win-${identifier}" class="resourcesDiv">
    <div class="box">
      <div class="title">${(elements_?.title)}</div>
      <div class="subtitle">${(elements_?.subtitle)}</div>

      <div class="flex">
        <div class="column">
          <img src="/images/fast.png" >
          <div class="flex_title">${(elements_?.title1)}</div>
          <div class="flex_text">${(elements_?.text1)}</div>
        </div>

        <div class="column">
          <img src="/images/free.png" >
          <div class="flex_title">${(elements_?.title2)}</div>
          <div class="flex_text">${(elements_?.text2)}</div>
        </div>

        <div class="column">
          <img src="/images/ai.png" >
          <div class="flex_title">${(elements_?.title3)}</div>
          <div class="flex_text">${(elements_?.text3)}</div>
        </div>

        <div class="column">
          <img src="/images/notifications.png" >
          <div class="flex_title">${(elements_?.title4)}</div>
          <div class="flex_text">${(elements_?.text4)}</div>
        </div>
      </div>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            resourcesDiv: `resourcesDiv-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`resourcesDiv-win-${identifier}`, elements, options, resourcesDiv_);
    return obj;
  }
} /**@private */
class footer_ extends Constructos {

  // ========================================
  /**
   * footer
   * @param {object} elements
   * @param {any} elements.line1  
   * @param {any} elements.year  
   * @param {any} elements.line2  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="footer-win-${identifier}" class="footer">
    <div class="box">
      <img src="/images/branding/icon_500_500_a3menage.png" width="50" height="50" alt="a3menage.com logotipo" >
      <span>${(elements_?.line1)} &bull; ${(elements_?.year)}</span>
      <span>${(elements_?.line2)}</span>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            footer: `footer-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`footer-win-${identifier}`, elements, options, footer_);
    return obj;
  }
} /**@private */
class footerMobile_ extends Constructos {

  // ========================================
  /**
   * footer mobile
   * @param {object} elements
   * @param {any} elements.line1  
   * @param {any} elements.year  
   * @param {any} elements.line2  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="footerMobile-win-${identifier}" class="footer">
    <div class="box">
      <img src="/images/branding/icon_500_500_a3menage.png" width="50" height="50" alt="a3menage.com logotipo" >
      <span>${(elements_?.line1)} &bull; ${(elements_?.year)}</span>
      <span class="_links">
        <a href="/infocenter/terms/pt">Terms</a> &bull;
        <a href="/infocenter/privacy/pt">Privacy</a>
      </span>
      <span>${(elements_?.line2)}</span>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            footerMobile: `footerMobile-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`footerMobile-win-${identifier}`, elements, options, footerMobile_);
    return obj;
  }
} /**@private */
class phone_ extends Constructos {

  // ========================================
  /**
   * phone
   * @param {object} [options]
   * @param {string} [options.identifier]
   * @param {object} [oldOptions]
   * @param {string} [oldOptions.identifier]
   */
  constructo = (options, oldOptions) => {
    if (!options?.identifier && oldOptions?.identifier) {
      options = oldOptions;
    }


    let elements = {};

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <img src="/images/phone-retina.png" id="phone-win-${identifier}" class="phone" >
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            phone: `phone-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`phone-win-${identifier}`, elements, options, phone_);
    return obj;
  }
} /**@private */
class arrow_down_pc_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} [options]
   * @param {string} [options.identifier]
   * @param {object} [oldOptions]
   * @param {string} [oldOptions.identifier]
   */
  constructo = (options, oldOptions) => {
    if (!options?.identifier && oldOptions?.identifier) {
      options = oldOptions;
    }


    let elements = {};

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="arrow_down_pc-win-${identifier}" class="arrow_down_pc">
    <div class="_img">
      <a href="#scroll-to">
        <img src="/images/arrow_alt.svg" class="animate__infinite arrow" id="arrow-win-${identifier}" >
      </a>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            arrow_down_pc: `arrow_down_pc-win-${identifier}`,
            arrow: `arrow-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`arrow_down_pc-win-${identifier}`, elements, options, arrow_down_pc_);
    return obj;
  }
} /**@private */
class divider1_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} [elements]
   * @param {any} [elements.style]  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="divider1-win-${identifier}" class="divider1" style="${(elements_?.style || "")}">
    <div class="custom-shape-divider-bottom-1690554366">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            divider1: `divider1-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`divider1-win-${identifier}`, elements, options, divider1_);
    return obj;
  }
} /**@private */
class divider2_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} [elements]
   * @param {any} [elements.style]  
   * @param {any} [elements.fill]  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="divider2-win-${identifier}" class="divider2" style="${(elements_?.style || "")}">
    <div class="custom-shape-divider-top-1690577011">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill" style="fill:${(elements_?.fill || "")}"></path>
      </svg>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            divider2: `divider2-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`divider2-win-${identifier}`, elements, options, divider2_);
    return obj;
  }
} /**@private */
class divider3_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} [elements]
   * @param {any} [elements.style]  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="divider3-win-${identifier}" class="divider3" style="${(elements_?.style || "")}">
    <div class="custom-shape-divider-bottom-1690721438">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            divider3: `divider3-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`divider3-win-${identifier}`, elements, options, divider3_);
    return obj;
  }
} /**@private */
class testimonials_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} [options]
   * @param {string} [options.identifier]
   * @param {object} [oldOptions]
   * @param {string} [oldOptions.identifier]
   */
  constructo = (options, oldOptions) => {
    if (!options?.identifier && oldOptions?.identifier) {
      options = oldOptions;
    }


    let elements = {};

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="testimonials-win-${identifier}" class="testimonials">
    <div class="inner">
      <span class="_title"> REVIEWS </span>
      <div class="reviews_output" id="reviews_output-win-${identifier}"></div>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            testimonials: `testimonials-win-${identifier}`,
            reviews_output: `reviews_output-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`testimonials-win-${identifier}`, elements, options, testimonials_);
    return obj;
  }
} /**@private */
class review_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} elements
   * @param {any} elements.stars  
   * @param {any} elements.text  
   * @param {any} elements.name  
   * @param {any} elements.age  
   * @param {any} elements.city  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="review-win-${identifier}" class="review">
    <span class="_stars"> ${(elements_?.stars)} </span>
    <span class="_text">${(elements_?.text)}</span>
    <div class="_user">${(elements_?.name)}, ${(elements_?.age)} - ${(elements_?.city)}</div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            review: `review-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`review-win-${identifier}`, elements, options, review_);
    return obj;
  }
} /**@private */
class splash1_pc_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} [options]
   * @param {string} [options.identifier]
   * @param {object} [oldOptions]
   * @param {string} [oldOptions.identifier]
   */
  constructo = (options, oldOptions) => {
    if (!options?.identifier && oldOptions?.identifier) {
      options = oldOptions;
    }


    let elements = {};

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="splash1_pc-win-${identifier}" class="splash1_pc">
    <div class="_inner" id="scroll-to">
      <img src="/images/two-girls-full.jpg" width="600px" class="girls" >
      <span class="title">
        Nós somos<br>
        a rede social<br>
        adulta<br>
        que mais<br>
        cresce<br>
        no Brasil
      </span>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            splash1_pc: `splash1_pc-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`splash1_pc-win-${identifier}`, elements, options, splash1_pc_);
    return obj;
  }
} /**@private */
class splash_mobile_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} [options]
   * @param {string} [options.identifier]
   * @param {object} [oldOptions]
   * @param {string} [oldOptions.identifier]
   */
  constructo = (options, oldOptions) => {
    if (!options?.identifier && oldOptions?.identifier) {
      options = oldOptions;
    }


    let elements = {};

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="splash_mobile-win-${identifier}" class="splash1">
    <img src="/images/jet.svg" class="jet" >
    <p class="title">
      Nós somos<br>
      a rede social<br>
      adulta<br>
      que mais<br>
      cresce<br>
      no Brasil
    </p>
    
    
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            splash_mobile: `splash_mobile-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`splash_mobile-win-${identifier}`, elements, options, splash_mobile_);
    return obj;
  }
} /**@private */
class div_cards_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} [options]
   * @param {string} [options.identifier]
   * @param {object} [oldOptions]
   * @param {string} [oldOptions.identifier]
   */
  constructo = (options, oldOptions) => {
    if (!options?.identifier && oldOptions?.identifier) {
      options = oldOptions;
    }


    let elements = {};

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="div_cards-win-${identifier}" class="div_cards">
    <div id="inner-win-${identifier}" class="div_cards_inner"></div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            div_cards: `div_cards-win-${identifier}`,
            inner: `inner-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`div_cards-win-${identifier}`, elements, options, div_cards_);
    return obj;
  }
} /**@private */
class card_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} elements
   * @param {any} elements.icon  
   * @param {any} elements.title  
   * @param {any} elements.text  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="card-win-${identifier}" class="card">
    <div class="icon">${(elements_?.icon)}</div>
    <div class="title title_">${(elements_?.title)}</div>
    <div class="text">${(elements_?.text)}</div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            card: `card-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`card-win-${identifier}`, elements, options, card_);
    return obj;
  }
} /**@private */
class screens_pc_ extends Constructos {

  // ========================================
  /**
   * 
   * @param {object} [options]
   * @param {string} [options.identifier]
   * @param {object} [oldOptions]
   * @param {string} [oldOptions.identifier]
   */
  constructo = (options, oldOptions) => {
    if (!options?.identifier && oldOptions?.identifier) {
      options = oldOptions;
    }


    let elements = {};

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div class="screens_pc" id="screens_pc-win-${identifier}">
    <img src="/images/screen2-b.jpg" class="img_screen img_screen1" >
    <img src="/images/screen1-b.jpg" class="img_screen img_screen2" >
    <img src="/images/screen3-b.jpg" class="img_screen img_screen3" >
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            screens_pc: `screens_pc-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`screens_pc-win-${identifier}`, elements, options, screens_pc_);
    return obj;
  }
} /**@private */
class smoke_ extends Constructos {

  // ========================================
  /**
   * smoke flowers
   * @param {object} elements
   * @param {any} elements.top  
   * @param {any} elements.left  
   * @param {any} elements.zindex  
   * @param {any} elements.angle  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <img src="/images/smoke.png" id="smoke-win-${identifier}" class="smoke" style="
    top:${(elements_?.top)}px;
    left:${(elements_?.left)}px;
    z-index:${(elements_?.zindex)};
    transform:rotate(${(elements_?.angle)}deg);
  " >
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            smoke: `smoke-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`smoke-win-${identifier}`, elements, options, smoke_);
    return obj;
  }
} /**@private */
class login_ extends Constructos {

  // ========================================
  /**
   * div login
   * @param {object} elements
   * @param {any} elements.title  
   * @param {any} elements.login_text  
   * @param {any} elements.pass_text  
   * @param {any} elements.show  
   * @param {any} elements.action  
   * @param {any} elements.submit_text  
   * @param {any} elements.action_forgot  
   * @param {any} elements.forgot  
   * @param {any} elements.action_register  
   * @param {any} elements.register  
   * @param {any} elements.action_back  
   * @param {any} elements.back  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="login-win-${identifier}" class="modalBackground">
    <div class="modal" id="modal-win-${identifier}">
      <div class="title">${(elements_?.title)}</div>

      <form onsubmit="return false" autocomplete="off" autocapitalize="off">
        <label>
          <p>${(elements_?.login_text)}</p>
          <span class="error" id="username_span-win-${identifier}"></span>
          <input type="text" name="username" id="username-win-${identifier}" >
        </label>
        <label>
          <p class="passShow">
            <span>${(elements_?.pass_text)}</span><span class="show" onclick="document.querySelector('.password').setAttribute('type','text')">${(elements_?.show)}</span>
          </p>
          <span class="error" id="password_span-win-${identifier}"></span>
          <input type="password" name="password" id="password-win-${identifier}" class="password" >
        </label>
        <button id="submit-win-${identifier}" onclick="${(elements_?.action)}">
          ${(elements_?.submit_text)}
        </button>
      </form>

      <button class="btn_link" onclick="${(elements_?.action_forgot)}">
        ${(elements_?.forgot)}
      </button>

      <button class="btn_link" onclick="${(elements_?.action_register)}">
        ${(elements_?.register)}
      </button>

      <button class="btn_link" onclick="${(elements_?.action_back)}">
        ${(elements_?.back)}
      </button>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            login: `login-win-${identifier}`,
            modal: `modal-win-${identifier}`,
            username_span: `username_span-win-${identifier}`,
            username: `username-win-${identifier}`,
            password_span: `password_span-win-${identifier}`,
            password: `password-win-${identifier}`,
            submit: `submit-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`login-win-${identifier}`, elements, options, login_);
    return obj;
  }
} /**@private */
class register_ extends Constructos {

  // ========================================
  /**
   * div register
   * @param {object} elements
   * @param {any} elements.title  
   * @param {any} elements.email_text  
   * @param {any} elements.pass_text  
   * @param {any} elements.show  
   * @param {any} elements.birth_text  
   * @param {any} elements.accept_text  
   * @param {any} elements.accept_link_text  
   * @param {any} elements.action  
   * @param {any} elements.submit_text  
   * @param {any} elements.action_login  
   * @param {any} elements.login  
   * @param {any} elements.action_back  
   * @param {any} elements.back  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="register-win-${identifier}" class="modalBackground">
    <div class="modal" id="modal-win-${identifier}">
      <div class="title">${(elements_?.title)}</div>

      <form onsubmit="return false" autocomplete="off" autocapitalize="off" autofill="off">
        <label>
          <p>${(elements_?.email_text)}</p>
          <span class="error" id="email_span-win-${identifier}"></span>
          <input autocomplete="off" type="email" name="email-register" id="email-win-${identifier}" >
        </label>

        <label>
          <p class="passShow">
            <span>${(elements_?.pass_text)}</span><span class="show" onclick="document.querySelector('.password').setAttribute('type','text')">${(elements_?.show)}</span>
          </p>
          <span class="error" id="password_span-win-${identifier}"></span>
          <input type="password" name="password-register" class="password" id="password-win-${identifier}" >
        </label>

        <label>
          <p>${(elements_?.birth_text)}</p>
          <span class="error" id="birth_span-win-${identifier}"></span>
          <input type="tel" name="birth-register" id="birth-win-${identifier}" placeholder="dd/mm/yyyy" >
        </label>

        <span class="error" id="check_span-win-${identifier}"></span>

        <label class="check">
          <input type="checkbox" class="input_check" name="accept" id="accept-win-${identifier}" >
          <span> ${(elements_?.accept_text)} ${(elements_?.accept_link_text)} </span>
        </label>

        <button id="submit-win-${identifier}" onclick="${(elements_?.action)}">
          ${(elements_?.submit_text)}
        </button>
      </form>

      <button class="btn_link" onclick="${(elements_?.action_login)}">
        ${(elements_?.login)}
      </button>

      <button class="btn_link" onclick="${(elements_?.action_back)}">
        ${(elements_?.back)}
      </button>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            register: `register-win-${identifier}`,
            modal: `modal-win-${identifier}`,
            email_span: `email_span-win-${identifier}`,
            email: `email-win-${identifier}`,
            password_span: `password_span-win-${identifier}`,
            password: `password-win-${identifier}`,
            birth_span: `birth_span-win-${identifier}`,
            birth: `birth-win-${identifier}`,
            check_span: `check_span-win-${identifier}`,
            accept: `accept-win-${identifier}`,
            submit: `submit-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`register-win-${identifier}`, elements, options, register_);
    return obj;
  }
} /**@private */
class lgpd_ extends Constructos {

  // ========================================
  /**
   * lgpd popup
   * @param {object} elements
   * @param {any} elements.text  
   * @param {any} elements.link  
   * @param {any} elements.action  
   * @param {any} elements.button  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="lgpd-win-${identifier}" class="lgpd">
    <div class="container">
      <div class="text">${(elements_?.text)}</div>
      <div class="link">
        <a href="/infocenter/privacy/pt"> ${(elements_?.link)} </a>
      </div>
      <button onclick="${(elements_?.action)}">${(elements_?.button)}</button>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            lgpd: `lgpd-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`lgpd-win-${identifier}`, elements, options, lgpd_);
    return obj;
  }
} /**@private */
class forgot_ extends Constructos {

  // ========================================
  /**
   * div forgot
   * @param {object} elements
   * @param {any} elements.title  
   * @param {any} elements.forgot_text  
   * @param {any} elements.action  
   * @param {any} elements.submit_text  
   * @param {any} elements.action_login  
   * @param {any} elements.login  
   * @param {any} elements.action_register  
   * @param {any} elements.register  
   * @param {any} elements.action_back  
   * @param {any} elements.back  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="forgot-win-${identifier}" class="modalBackground">
    <div class="modal" id="modal-win-${identifier}">
      <div class="title">${(elements_?.title)}</div>

      <form onsubmit="return false" autocomplete="off" autocapitalize="off">
        <label>
          <p>${(elements_?.forgot_text)}</p>
          <span class="error" id="username_span-win-${identifier}"></span>
          <input type="text" name="username" id="username-win-${identifier}" >
        </label>
        <button id="submit-win-${identifier}" onclick="${(elements_?.action)}">
          ${(elements_?.submit_text)}
        </button>
        <div id="successMessage-win-${identifier}"></div>
      </form>

      <button class="btn_link" onclick="${(elements_?.action_login)}">
        ${(elements_?.login)}
      </button>

      <button class="btn_link" onclick="${(elements_?.action_register)}">
        ${(elements_?.register)}
      </button>

      <button class="btn_link" onclick="${(elements_?.action_back)}">
        ${(elements_?.back)}
      </button>
    </div>
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            forgot: `forgot-win-${identifier}`,
            modal: `modal-win-${identifier}`,
            username_span: `username_span-win-${identifier}`,
            username: `username-win-${identifier}`,
            submit: `submit-win-${identifier}`,
            successMessage: `successMessage-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`forgot-win-${identifier}`, elements, options, forgot_);
    return obj;
  }
} /**@private */
class button_ extends Constructos {

  // ========================================
  /**
   * default button
   * @param {object} elements
   * @param {any} [elements.class]  
   * @param {any} [elements.action]  
   * @param {any} elements.text  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <button id="button-win-${identifier}" class="${(elements_?.class || "")}" style="margin-top: 20px; padding: 20px 40px" onclick="${(elements_?.action || "")}">
    ${(elements_?.text)}
  </button>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            button: `button-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`button-win-${identifier}`, elements, options, button_);
    return obj;
  }
} /**@private */
class simpleText_ extends Constructos {

  // ========================================
  /**
   * simple text
   * @param {object} elements
   * @param {any} [elements.class]  
   * @param {any} elements.text  
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="simpleText-win-${identifier}" class="${(elements_?.class || "")}" style="font-weight: normal">
    ${(elements_?.text)}
  </div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            simpleText: `simpleText-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`simpleText-win-${identifier}`, elements, options, simpleText_);
    return obj;
  }
} /**@private */
class space_ extends Constructos {

  // ========================================
  /**
   * space separator
   * @param {object} [options]
   * @param {string} [options.identifier]
   * @param {object} [oldOptions]
   * @param {string} [oldOptions.identifier]
   */
  constructo = (options, oldOptions) => {
    if (!options?.identifier && oldOptions?.identifier) {
      options = oldOptions;
    }


    let elements = {};

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <div id="space-win-${identifier}" class="space"></div>
`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            space: `space-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`space-win-${identifier}`, elements, options, space_);
    return obj;
  }
}

export const country = new country_().constructo;
export const h2 = new h2_().constructo;
export const mainDiv = new mainDiv_().constructo;
export const mainDivMobile = new mainDivMobile_().constructo;
export const mainDivApp = new mainDivApp_().constructo;
export const resourcesDiv = new resourcesDiv_().constructo;
export const footer = new footer_().constructo;
export const footerMobile = new footerMobile_().constructo;
export const phone = new phone_().constructo;
export const arrow_down_pc = new arrow_down_pc_().constructo;
export const divider1 = new divider1_().constructo;
export const divider2 = new divider2_().constructo;
export const divider3 = new divider3_().constructo;
export const testimonials = new testimonials_().constructo;
export const review = new review_().constructo;
export const splash1_pc = new splash1_pc_().constructo;
export const splash_mobile = new splash_mobile_().constructo;
export const div_cards = new div_cards_().constructo;
export const card = new card_().constructo;
export const screens_pc = new screens_pc_().constructo;
export const smoke = new smoke_().constructo;
export const login = new login_().constructo;
export const register = new register_().constructo;
export const lgpd = new lgpd_().constructo;
export const forgot = new forgot_().constructo;
export const button = new button_().constructo;
export const simpleText = new simpleText_().constructo;
export const space = new space_().constructo;