import {
  Winnetou
} from "../../node_modules/winnetoujs/src/winnetou.js";
import {
  Constructos
} from "../../node_modules/winnetoujs/src/constructos.js";



/**@private */
class icons_Twitter_X_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_Twitter_X**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
                  <svg viewBox="0 0 1668.56 1221.19" id="icons_Twitter_X-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
                  <g id="layer1" transform="translate(52.390088,-25.058597)">
<path id="path1009" d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"></path>
</g>
</svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_Twitter_X: `icons_Twitter_X-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_Twitter_X-win-${identifier}`, elements, options, icons_Twitter_X_);
    return obj;
  }
} /**@private */
class icons_bellSolid_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_bellSolid**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
                  <svg viewBox="0 0 448 512" id="icons_bellSolid-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
                  <path data-fill="currentColor" d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z" class=""></path>
                </svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_bellSolid: `icons_bellSolid-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_bellSolid-win-${identifier}`, elements, options, icons_bellSolid_);
    return obj;
  }
} /**@private */
class icons_chat2_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_chat2**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
                <svg viewBox="0 0 32 32" id="icons_chat2-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
                <path d="M25,8.2c-0.3-4-3.6-7.2-7.7-7.2H8.8C4.5,1,1,4.5,1,8.7V23c0,0.4,0.2,0.7,0.6,0.9C1.7,24,1.9,24,2,24c0.2,0,0.5-0.1,0.7-0.2
c1.3-1.1,2.8-2,4.3-2.7V30c0,0.4,0.2,0.7,0.6,0.9C7.7,31,7.9,31,8,31c0.2,0,0.5-0.1,0.7-0.2c2.9-2.6,6.7-4,10.6-4h4
c4.3,0,7.8-3.5,7.8-7.7v-3.4C31,12.1,28.4,9,25,8.2z M29,19.1c0,3.2-2.6,5.7-5.8,5.7h-4C15.6,24.8,12,25.9,9,28v-7.6
c1.4-0.4,2.8-0.6,4.3-0.6h4c4.3,0,7.8-3.5,7.8-7.7v-1.8c2.3,0.7,4,2.9,4,5.4V19.1z"></path>
</svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_chat2: `icons_chat2-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_chat2-win-${identifier}`, elements, options, icons_chat2_);
    return obj;
  }
} /**@private */
class icons_download_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_download**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
                <svg viewBox="0 0 512 512" id="icons_download-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
                <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"></path></svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_download: `icons_download-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_download-win-${identifier}`, elements, options, icons_download_);
    return obj;
  }
} /**@private */
class icons_jet_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_jet**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
                <svg viewBox="0 0 640 512" id="icons_jet-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
                <path d="M130 480c40.6 0 80.4-11 115.2-31.9L352 384l-224 0 0 96h2zM352 128L245.2 63.9C210.4 43 170.6 32 130 32h-2v96l224 0zM96 128l0-96H80C53.5 32 32 53.5 32 80v48h8c-22.1 0-40 17.9-40 40v16V328v16c0 22.1 17.9 40 40 40H32v48c0 26.5 21.5 48 48 48H96l0-96h8c26.2 0 49.4-12.6 64-32H456c69.3 0 135-22.7 179.2-81.6c6.4-8.5 6.4-20.3 0-28.8C591 182.7 525.3 160 456 160H168c-14.6-19.4-37.8-32-64-32l-8 0zM512 243.6v24.9c0 19.6-15.9 35.6-35.6 35.6c-2.5 0-4.4-2-4.4-4.4V212.4c0-2.5 2-4.4 4.4-4.4c19.6 0 35.6 15.9 35.6 35.6z"></path></svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_jet: `icons_jet-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_jet-win-${identifier}`, elements, options, icons_jet_);
    return obj;
  }
} /**@private */
class icons_menu_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_menu**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
                <svg viewBox="0 0 448 512" id="icons_menu-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path></svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_menu: `icons_menu-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_menu-win-${identifier}`, elements, options, icons_menu_);
    return obj;
  }
} /**@private */
class icons_messages_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_messages**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
                <svg viewBox="0 0 576 512" id="icons_messages-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
                <path data-fill="currentColor" d="M416 224V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v160c0 35.3 28.7 64 64 64v54.2c0 8 9.1 12.6 15.5 7.8l82.8-62.1H352c35.3.1 64-28.6 64-63.9zm96-64h-64v64c0 52.9-43.1 96-96 96H192v64c0 35.3 28.7 64 64 64h125.7l82.8 62.1c6.4 4.8 15.5.2 15.5-7.8V448h32c35.3 0 64-28.7 64-64V224c0-35.3-28.7-64-64-64z" class=""></path>
              </svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_messages: `icons_messages-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_messages-win-${identifier}`, elements, options, icons_messages_);
    return obj;
  }
} /**@private */
class icons_notepad_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_notepad**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
              <svg viewBox="0 0 576 512" id="icons_notepad-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
              <path data-fill="currentColor" d="M568.54 167.33l-31.87-31.87c-9.94-9.94-26.07-9.94-36.01 0l-27.25 27.25 67.88 67.88 27.25-27.25c9.95-9.94 9.95-26.07 0-36.01zM329.06 306a63.974 63.974 0 0 0-16.26 27.11L297.57 384h-24.76c-4.28 0-8.12-2.38-10.16-6.5-11.97-23.86-46.28-30.34-66-14.17l-13.88-41.62c-3.28-9.81-12.44-16.41-22.78-16.41s-19.5 6.59-22.78 16.41L119 376.36c-1.5 4.58-5.78 7.64-10.59 7.64H96c-8.84 0-16 7.16-16 16s7.16 16 16 16h12.41c18.62 0 35.09-11.88 40.97-29.53L160 354.58l16.81 50.48a15.994 15.994 0 0 0 14.06 10.89c.38.03.75.05 1.12.05 6.03 0 11.59-3.41 14.31-8.86l7.66-15.33c2.78-5.59 7.94-6.19 10.03-6.19s7.25.59 10.19 6.53c7.38 14.7 22.19 23.84 38.62 23.84H336V464H47.99V48.09h159.97v103.98c0 13.3 10.7 23.99 24 23.99H336v78l48-47.58v-74.5c0-12.7-5.17-25-14.17-33.99L285.94 14.1c-9-9-21.2-14.1-33.89-14.1H47.99C21.5.1 0 21.6 0 48.09v415.92C0 490.5 21.5 512 47.99 512h287.94c26.5 0 48.07-21.5 48.07-47.99V388.8l134.66-135.58-67.88-67.88L329.06 306zM255.95 51.99l76.09 76.08h-76.09V51.99z" class=""></path>
            </svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_notepad: `icons_notepad-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_notepad-win-${identifier}`, elements, options, icons_notepad_);
    return obj;
  }
} /**@private */
class icons_real_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_real**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
            <svg viewBox="0 0 512 512" id="icons_real-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
            <path data-fill="currentColor" d="M332.73 178.37c-3.85-3.88-10.11-3.9-13.98-.06l-87.36 86.66-37.88-38.19c-3.84-3.88-10.11-3.9-13.98-.06l-23.4 23.21c-3.88 3.85-3.9 10.11-.06 13.98l68.05 68.6c3.85 3.88 10.11 3.9 13.98.06l117.78-116.83c3.88-3.85 3.9-10.11.06-13.98l-23.21-23.39zM512 256c0-36.5-18.8-68.4-46.7-86.7 6.8-32.9-2.7-68.7-28.3-94.3-25.6-25.7-61.5-35.1-94.3-28.3C324.2 18.7 292.3 0 256 0s-68.3 18.7-86.7 46.7C136.4 39.9 100.6 49.4 75 75c-25.7 25.6-35.1 61.5-28.3 94.3C18.6 187.8 0 219.8 0 256c0 36.3 18.7 68.3 46.7 86.7-6.8 32.9 2.7 68.7 28.3 94.3 25.6 25.7 61.2 35.1 94.3 28.3 18.5 28.1 50.5 46.7 86.7 46.7 36.4 0 68.3-18.8 86.7-46.7 33.1 6.8 68.7-2.7 94.3-28.3 25.7-25.6 35.1-61.5 28.3-94.3 28.1-18.5 46.7-50.5 46.7-86.7zm-108.3 61.3c9.1 19.9 30.3 54.8-.7 85.8-28 28-55.3 14.5-85.8.7-7.7 20.6-17.3 60.2-61.2 60.2-45.3 0-54.7-42.8-61.2-60.2-21.1 9.6-54.9 30.2-85.9-.8-32-32-8.4-68.9-.7-85.8C87.6 309.5 48 299.9 48 256c0-45.3 42.8-54.7 60.3-61.3-9.1-19.9-30.3-54.8.7-85.8 32-32 68.9-8.4 85.8-.7C202.5 87.6 212.1 48 256 48c45.3 0 54.7 42.8 61.2 60.4 19.9-9.1 54.8-30.3 85.8.7 32 32 8.4 68.9.7 85.8 20.6 7.7 60.2 17.3 60.2 61.2 0 45.3-42.8 54.7-60.2 61.2z" class=""></path>
          </svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_real: `icons_real-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_real-win-${identifier}`, elements, options, icons_real_);
    return obj;
  }
} /**@private */
class icons_search_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_search**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
          <svg viewBox="0 0 512 512" id="icons_search-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
          <path data-fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" class=""></path>
        </svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_search: `icons_search-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_search-win-${identifier}`, elements, options, icons_search_);
    return obj;
  }
} /**@private */
class icons_signin_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_signin**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
        <svg viewBox="0 0 512 512" id="icons_signin-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
        <path d="M352 96l64 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0c53 0 96-43 96-96l0-256c0-53-43-96-96-96l-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32zm-9.4 182.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L242.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"></path></svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_signin: `icons_signin-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_signin-win-${identifier}`, elements, options, icons_signin_);
    return obj;
  }
} /**@private */
class icons_square_facebook_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_square_facebook**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
        <svg viewBox="0 0 448 512" id="icons_square_facebook-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
        <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"></path></svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_square_facebook: `icons_square_facebook-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_square_facebook-win-${identifier}`, elements, options, icons_square_facebook_);
    return obj;
  }
} /**@private */
class icons_square_twitter_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_square_twitter**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
        <svg viewBox="0 0 448 512" id="icons_square_twitter-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
        <path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"></path></svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_square_twitter: `icons_square_twitter-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_square_twitter-win-${identifier}`, elements, options, icons_square_twitter_);
    return obj;
  }
} /**@private */
class icons_star_empty_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_star_empty**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
        <svg viewBox="0 0 20 19" id="icons_star_empty-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
        
        <title>star_border</title>
        <desc>Created with Sketch.</desc>
        <g id="Icons" stroke="none" stroke-width="1" data-fill="none" fill-rule="evenodd">
          <g id="Outlined" transform="translate(-238.000000, -4322.000000)">
            <g id="Toggle" transform="translate(100.000000, 4266.000000)">
              <g id="Outlined-/-Toggle-/-star_border" transform="translate(136.000000, 54.000000)">
                <g>
                  <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                  <path d="M22,9.24 L14.81,8.62 L12,2 L9.19,8.63 L2,9.24 L7.46,13.97 L5.82,21 L12,17.27 L18.18,21 L16.55,13.97 L22,9.24 Z M12,15.4 L8.24,17.67 L9.24,13.39 L5.92,10.51 L10.3,10.13 L12,6.1 L13.71,10.14 L18.09,10.52 L14.77,13.4 L15.77,17.68 L12,15.4 Z" id="🔹-Icon-Color" fill="#1D1D1D"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_star_empty: `icons_star_empty-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_star_empty-win-${identifier}`, elements, options, icons_star_empty_);
    return obj;
  }
} /**@private */
class icons_star_full_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_star_full**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
      <svg viewBox="0 0 20 19" id="icons_star_full-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
      
      <title>star</title>
      <desc>Created with Sketch.</desc>
      <g id="Icons" stroke="none" stroke-width="1" data-fill="none" fill-rule="evenodd">
        <g id="Outlined" transform="translate(-272.000000, -4322.000000)">
          <g id="Toggle" transform="translate(100.000000, 4266.000000)">
            <g id="Outlined-/-Toggle-/-star" transform="translate(170.000000, 54.000000)">
              <g>
                <polygon id="Path" fill-rule="nonzero" points="0 0 24 0 24 24 0 24"></polygon>
                <polygon id="🔹-Icon-Color" fill="#1D1D1D" points="12 17.27 18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_star_full: `icons_star_full-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_star_full-win-${identifier}`, elements, options, icons_star_full_);
    return obj;
  }
} /**@private */
class icons_star_half_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_star_half**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
    <svg viewBox="0 0 20 19" id="icons_star_half-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
    
    <title>star_half</title>
    <desc>Created with Sketch.</desc>
    <g id="Icons" stroke="none" stroke-width="1" data-fill="none" fill-rule="evenodd">
      <g id="Outlined" transform="translate(-204.000000, -4322.000000)">
        <g id="Toggle" transform="translate(100.000000, 4266.000000)">
          <g id="Outlined-/-Toggle-/-star_half" transform="translate(102.000000, 54.000000)">
            <g>
              <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
              <path d="M22,9.24 L14.81,8.62 L12,2 L9.19,8.63 L2,9.24 L7.46,13.97 L5.82,21 L12,17.27 L18.18,21 L16.55,13.97 L22,9.24 Z M12,15.4 L12,6.1 L13.71,10.14 L18.09,10.52 L14.77,13.4 L15.77,17.68 L12,15.4 Z" id="🔹-Icon-Color" fill="#1D1D1D"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_star_half: `icons_star_half-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_star_half-win-${identifier}`, elements, options, icons_star_half_);
    return obj;
  }
} /**@private */
class icons_video_ extends Constructos {

  // ========================================
  /**
   * Create an icon **icons_video**
   * @param {object} [elements]
   * @param {any} [elements.class]  Class for the icon
   * @param {object} [options]
   * @param {string} [options.identifier]
   */
  constructo = (elements, options) => {

    let identifier = this._mutableToString(options);
    identifier = this._getIdentifier(options ? identifier.identifier || 'notSet' : 'notSet');
    let elementsToString = this._mutableToString(elements);
    let component;
    let obj = {
      code(elements_) {
        return `
  <svg viewBox="0 0 576 512" id="icons_video-win-${identifier}" class="winIcons ${(elements_?.class || "")}">
  <path data-fill="currentColor" d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z" class=""></path>
</svg>`
      },

      /**
       * Create Winnetou Constructo        
       * @param  {object|string} output The node or list of nodes where the component will be created
       * @param  {object} [options] Options to control how the construct is inserted. Optional.
       * @param  {boolean} [options.clear] Clean the node before inserting the construct
       * @param  {boolean} [options.reverse] Place the construct in front of other constructs
       * @param {object} [options.vdom] Winnetou.vdom() fragment
       * @param {boolean} [options.replace] Replace a constructo
       */

      "create": (output, options) => {
        this.create(component, output, options);
        return {
          ids: {
            icons_video: `icons_video-win-${identifier}`,
          },
        }
      },
      constructoString: () => obj.code(elementsToString)
    }
    component = obj.code(elementsToString);
    this._saveUsingMutable(`icons_video-win-${identifier}`, elements, options, icons_video_);
    return obj;
  }
}

export const icons_Twitter_X = new icons_Twitter_X_().constructo;
export const icons_bellSolid = new icons_bellSolid_().constructo;
export const icons_chat2 = new icons_chat2_().constructo;
export const icons_download = new icons_download_().constructo;
export const icons_jet = new icons_jet_().constructo;
export const icons_menu = new icons_menu_().constructo;
export const icons_messages = new icons_messages_().constructo;
export const icons_notepad = new icons_notepad_().constructo;
export const icons_real = new icons_real_().constructo;
export const icons_search = new icons_search_().constructo;
export const icons_signin = new icons_signin_().constructo;
export const icons_square_facebook = new icons_square_facebook_().constructo;
export const icons_square_twitter = new icons_square_twitter_().constructo;
export const icons_star_empty = new icons_star_empty_().constructo;
export const icons_star_full = new icons_star_full_().constructo;
export const icons_star_half = new icons_star_half_().constructo;
export const icons_video = new icons_video_().constructo;