import {
  button,
  simpleText,
  space,
} from "../js/constructos/commons.js";
import { full_screen_loader } from "../js/constructos/loaders.js";
import { Winnetou } from "../node_modules/winnetoujs/src/winnetou.js";

/**
 * Cria uma div fixed de loader fullscreen
 * @param {object} data
 * @param {"general"|"pix_generation"} data.type
 */
export const fullScreenLoader = data => {
  let text;
  switch (data.type) {
    case "general":
      text = "Aguarde... não feche o aplicativo.";
      break;
    case "pix_generation":
      text = "";
      break;
  }

  let _ = full_screen_loader({
    text,
  }).create("#app");

  setTimeout(() => {
    space().create(_.ids.full_screen_loader);
    space().create(_.ids.full_screen_loader);

    simpleText({
      text: "Esta requisição está demorando mais do que deveria. Clique no botão abaixo para voltar.",
      class: "warn",
    }).create(_.ids.full_screen_loader);

    space().create(_.ids.full_screen_loader);
    space().create(_.ids.full_screen_loader);

    button({
      text: "Voltar",
      action: Winnetou.fx(() => {
        Winnetou.select(_.ids.full_screen_loader).remove();
      }),
    }).create(_.ids.full_screen_loader);
  }, 20000);

  return {
    remove: () => Winnetou.select(_.ids.full_screen_loader).remove(),
  };
};
