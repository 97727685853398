import { Winnetou } from "../node_modules/winnetoujs/src/winnetou.js";
const dayAsMilliseconds = 1000 * 60 * 60 * 24;
const minutesAsMilliseconds = 1000 * 60;

/**
 * Subtract date2 from date1 and return
 * total in days
 * @param {string|Date} date1
 * @param {string|Date} date2
 */
export const subtractDays = (date1, date2) => {
  try {
    date1 = new Date(date1);
  } catch (e) {}
  try {
    date2 = new Date(date2);
  } catch (e) {}

  //@ts-ignore
  let a = (date2 - date1) / dayAsMilliseconds;

  return Math.floor(a);
};

/**
 * Subtract date2 from date1 and return
 * total in minutes
 * @param {string|Date} date1
 * @param {string|Date} date2
 */
export const subtractMinutes = (date1, date2) => {
  try {
    date1 = new Date(date1);
  } catch (e) {}
  try {
    date2 = new Date(date2);
  } catch (e) {}

  //@ts-ignore
  let a = (date2 - date1) / minutesAsMilliseconds;

  return Math.floor(a);
};

/**
 * Testa o formato da data
 * @param {string} date a data final no formato dd/mm/yyyy
 * @returns {boolean} true se o formato estiver correto
 */
export const checkDateFormat = date =>
  /^(\d{2})\/(\d{2})\/(\d{4})$/.test(date);

/**
 * Testa se o usuário
 * é maio de 18 anos
 * @param {string} birth a data de nascimento no formato dd/mm/yyyy
 * @returns {boolean} false if under age
 */
export const ageVerification = birth => {
  //

  if (!checkDateFormat(birth)) {
    console.error(
      `Age Verification Error: Birth date error, code 34343d. The informed value was ${birth} but needs to be dd/mm/yyyy.`
    );
    return false;
  }

  let a = birth.split("/");
  let b = `${a[2]}-${a[1]}-${a[0]}`;
  birth = b;

  let birthdate = new Date(birth).getTime();
  let today = new Date().getTime();

  var myAge = ~~((today - birthdate) / 31557600000);

  if (myAge < 18) {
    return false;
  } else {
    return true;
  }
};

/**
 * Função a ser adicionada ao listener do
 * input, formatará a data e prevenirá
 * caracteres indesejados
 * O input deve ser do tipo `tel`
 * para mostrar apenas números no mobile
 * e ainda aceitar `/`.
 * Use apenas o método `addListerDateChange`.
 * @param {any} ev window event
 * @param {string} inputId o id do input, `form1.ids.birthDate`
 * @example
 * document
 * .getElementById(r.ids.birth)
 * .addEventListener("keyup", ev => {
 *  dataChange(ev,r.ids.birth);
 * });
 *
 */
const dateChange = (ev, inputId) => {
  if (ev.keyCode === 8 || ev.key === "Backspace") {
    return;
  }

  let bDate = Winnetou.select(inputId).getVal();
  //retira os caracteres indesejados...
  bDate = bDate.replace(/[^\d\/]/g, "");

  //realizar a formatação...
  let bd2 = "";
  bDate.length == 2 && (bd2 = bDate.replace(/(\d{2})/, "$1/"));

  bDate.length == 5 &&
    (bd2 = bDate.replace(/(\d{2})\/(\d{2})/, "$1/$2/"));

  bDate.length == 10 &&
    (bd2 = bDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$1/$2/$3"));

  bDate =
    bd2 == ""
      ? bDate.replace(/[^\d\/]/g, "")
      : bd2.replace(/[^\d\/]/g, "");

  // testa o formato final
  if (bDate.length >= 10) {
    let match = /^(\d{2})\/(\d{2})\/(\d{4})$/.exec(bDate);
    if (!match) {
      Winnetou.select(inputId).setVal("");
      return;
    }
  }

  Winnetou.select(inputId).setVal(bDate);
};

/**
 * Irá colocar o listener no input para
 * validar o formato.
 * Sempre chame esta função depois de o constructo ter
 * sido criado.
 * @param {string} id O id do input, exemplo: `r.ids.birth`
 */
export const addListerDateChange = id => {
  document.getElementById(id).addEventListener("keyup", ev => {
    dateChange(ev, id);
  });
};
