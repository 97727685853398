/**
 * Return string url in promise only when img is already cached.
 * @param  {string} url
 * @returns {Promise<string>} url
 */
export function getImage(url) {
  return new Promise(function (resolve, reject) {
    var img = new Image();
    img.onload = function () {
      resolve(url);
    };
    img.onerror = function () {
      reject(url);
    };
    try {
      img.src = url;
    } catch (e) {
      return reject("getImage Error: " + e.message);
    }
  });
}
